<template>
	<div class="row">
		<div
			class="col-6 col-sm-4"
			v-for="tutorial in myTutorials"
			:key="tutorial.name"
		>
			<h6>{{ tutorial.name }}</h6>
			<hr />
			<div class="wrapper-video" v-html="tutorial.video"></div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters(['lang', 'myTutorials']),
	},
	mounted() {
		document.title = 'Dashboard - ITFA'
	},
}
</script>
